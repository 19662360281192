export const vueI18n = {"fallbackLocale":"nl","messages":{"nl":{"components":{"header":{"main_menu":{"home":"Home","shop":"Shop","about_us":"Over ons","contact":"Contact","cart":"Winkelwagen","account":"Account"}},"footer":{"contact":"Contactgegevens","text":"Antoniusstraat 13A<br />5408 AM  Volkel<br /><a href='tel:0031657705079'>+31 (0)6 57 70 50 79</a><br /><a href='mailto:info@mypall.com'>info@mypall.com</a>","menu":{"disclaimer":"Disclaimer","conditions":"Algemene voorwaarden","privacy":"Privacy"}}},"form":{"email":"E-mailadres","name":"Naam","message":"Bericht","send":"verzenden","thank_you":"Bedankt voor je bericht, wij nemen deze zo spoedig mogelijk in behandeling.","fail":"Er is iets fout gegaan bij het versturen, probeer het nog eens.","false_email":"Dit e-mailadres is niet geldig."},"pages":{}}}}
export const vueI18nLoader = false
export const locales = [{"code":"nl","name":"NL","file":"nl-NL.json"}]
export const defaultLocale = 'nl'
export const routesNameSeparator = '___'
export const defaultLocaleRouteNameSuffix = 'default'
export const strategy = 'prefix_except_default'
export const lazy = true
export const langDir = './assets/locales/'
export const rootRedirect = null
export const detectBrowserLanguage = false
export const differentDomains = false
export const seo = false
export const baseUrl = ''
export const vuex = {"moduleName":"i18n","syncLocale":false,"syncMessages":false,"syncRouteParams":true}
export const parsePages = false
export const pages = {"index":{"nl":"/"},"about-us/index":{"nl":"/over-ons"},"contact/index":{"nl":"/contact"},"account/index":{"nl":"/account"},"cart/index":{"nl":"/winkelwagen"},"shop":{"nl":"/shop"},"shop/_product":{"nl":"/shop/:product"},"conditions/index":{"nl":"/algemene-voorwaarden"},"privacy/index":{"nl":"/privacy"}}
export const beforeLanguageSwitch = () => null
export const onLanguageSwitched = () => null
export const IS_UNIVERSAL_MODE = true
export const MODULE_NAME = 'nuxt-i18n'
export const LOCALE_CODE_KEY = 'code'
export const LOCALE_ISO_KEY = 'iso'
export const LOCALE_DOMAIN_KEY = 'domain'
export const LOCALE_FILE_KEY = 'file'
export const STRATEGIES = {"PREFIX":"prefix","PREFIX_EXCEPT_DEFAULT":"prefix_except_default","PREFIX_AND_DEFAULT":"prefix_and_default","NO_PREFIX":"no_prefix"}
export const COMPONENT_OPTIONS_KEY = 'nuxtI18n'
export const localeCodes = ["nl"]
