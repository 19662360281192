import {magento, token, oauth, baseUrl} from '~/service/magento'

export const strict = false

export const state = () => ({
    language          : 'nl',
    products          : [],
    attributes        : [],
    cartId            : null,
    bearer            : null,
    cart              : {},
    shippingInfo      : 0,
    amountInCart      : 0,
    orderHistory      : {},
    loadingCart       : false,
    pushedOrder       : '',
    editingCart       : false,
    editingCartMessage: '',
    successCartMessage: '',
    pushOrderError    : '',
    ordering          : false
})

export const getters   = {
    getLanguage(state) {
        return state.language
    },
    getProducts(state) {
        return state.products
    },
    getAttributes(state) {
        return state.attributes
    },
    cart(state) {
        return state.cart
    },
    cartItems(state) {
        return state.cartItems
    },
    shippingInfo(state) {
        return state.shippingInfo
    },
    bearer(state) {
        return state.bearer
    },
    cartId(state) {
        return state.cartId
    },
    amountInCart(state) {
        return state.amountInCart
    },
    orderHistory(state) {
        return state.orderHistory
    },
    loadingCart(state) {
        return state.loadingCart
    },
    pushedOrder(state) {
        return state.pushedOrder
    },
    editingCart(state) {
        return state.editingCart
    },
    editingCartMessage(state) {
        return state.editingCartMessage
    },
    successCartMessage(state) {
        return state.successCartMessage
    },
    pushOrderError(state) {
        return state.pushOrderError
    },
    ordering(state) {
        return state.ordering
    }
}
export const mutations = {
    SET_LANGUAGE(state, language) {
        state.language = language
    },
    SET_PRODUCTS(state, products) {
        state.products = products
    },
    SET_ATTRIBUTES(state, attributes) {
        state.attributes = attributes
    },
    SET_CART_ID(state, cartId) {
        state.cartId = cartId
    },
    SET_BEARER(state, bearer) {
        state.bearer = bearer
    },
    SET_CART(state, cart) {
        state.cart = cart
    },
    SET_CART_ITEMS(state, cartItems) {
        state.cartItems = cartItems
    },
    SET_SHIPPING_INFO(state, shippingInfo) {
        state.shippingInfo = shippingInfo
    },
    SET_AMOUNT_IN_CART(state, amountInCart) {
        state.amountInCart = amountInCart
    },
    SET_ORDER_HISTORY(state, orderHistory) {
        state.orderHistory = orderHistory
    },
    SET_LOADING_CART_STATUS(state, loadingCart) {
        state.loadingCart = loadingCart
    },
    SET_PUSHED_ORDER_STATUS(state, pushedOrder) {
        state.pushedOrder = pushedOrder
    },
    SET_EDITING_CART_STATUS(state, editingCart) {
        state.editingCart = editingCart
    },
    SET_EDITING_CART_MESSAGE(state, editingCartMessage) {
        state.editingCartMessage = editingCartMessage
    },
    SET_SUCCESS_CART_MESSAGE(state, successCartMessage) {
        state.successCartMessage = successCartMessage
    },
    SET_PUSH_ORDER_ERROR_MESSAGE(state, pushOrderError) {
        state.pushOrderError = pushOrderError
    },
    SET_ORDERING_STATUS(state, ordering) {
        state.ordering = ordering
    }
}
export const actions   = {
    async nuxtServerInit({commit, $route}, {redirect, res, app, req}) {

        commit('SET_LANGUAGE', 'nl')


        if (req.originalUrl.indexOf('/shop') !== -1) {

            const productsRequest        = {
                url   : `${baseUrl}products?searchCriteria[filterGroups][0][filters][0][field]=category_id&searchCriteria[filterGroups][0][filters][0][value]=3&searchCriteria[filterGroups][0][filters][0][conditionType]=in&searchCriteria[sortOrders][0][field]=created_at&searchCriteria[sortOrders][0][direction]=desc`,
                method: 'GET'
            }
            const attributeList          = '93,140'
            const attributeRequest       = {
                url   : `${baseUrl}products/attributes?searchCriteria[filterGroups][0][filters][0][field]=attribute_id&searchCriteria[filterGroups][0][filters][0][value]=${attributeList}&searchCriteria[filterGroups][0][filters][0][conditionType]=in`,
                method: 'GET'
            }
            const [products, attributes] = await Promise.all([
                magento.get(productsRequest.url, {
                    headers: oauth.toHeader(oauth.authorize(productsRequest, token))
                }),
                magento.get(attributeRequest.url, {
                    headers: oauth.toHeader(oauth.authorize(attributeRequest, token))
                })
            ])
            commit('SET_PRODUCTS', products.data.items)
            commit('SET_ATTRIBUTES', attributes.data.items)
        }



        if (app.$auth.loggedIn) {
            const cartId = magento.post(
                `${baseUrl}carts/mine`,
                {},
                {headers: {Authorization: app.$auth.$storage._state._token_local}}
            )
            const cart   = magento.get(`${baseUrl}carts/mine`, {
                headers: {Authorization: app.$auth.$storage._state._token_local}
            })

            await Promise.all([cartId, cart]).then((values) => {
                let amountInCart = 0
                values[1].data.items.map((i) => {
                    amountInCart += i.qty
                })

                commit('SET_AMOUNT_IN_CART', amountInCart)
                commit('SET_CART_ID', values[0].data)
                commit('SET_BEARER', app.$auth.$storage._state._token_local)
            })
        } else {
            let amountInCart   = 0
            let cart           = {}
            const cartIdCookie = app.$cookies.get('cart_id')

            if (req.headers.cookie) {
                if (cartIdCookie) {
                    const [fetchedCart] = await Promise.all([
                        magento.get(`${baseUrl}guest-carts/${cartIdCookie}`)
                    ])

                    commit('SET_CART_ID', cartIdCookie)
                    cart = fetchedCart
                } else {
                    const [fetchedCart] = await Promise.all([
                        magento.post(`${baseUrl}guest-carts`)
                    ])

                    commit('SET_CART_ID', fetchedCart.data)
                    app.$cookies.set('cart_id', fetchedCart.data, Infinity)

                    cart = await magento.get(`${baseUrl}guest-carts/${fetchedCart.data}`)
                }
            } else {
                const [fetchedCart] = await Promise.all([
                    magento.post(`${baseUrl}guest-carts`)
                ])

                commit('SET_CART_ID', fetchedCart.data)
                app.$cookies.set('cart_id', fetchedCart.data, Infinity)

                cart = {data: {items: [{qty: 0}]}}
            }

            if (cart.data.items) {
                cart.data.items.map((i) => {
                    amountInCart += i.qty
                })
            }

            commit('SET_AMOUNT_IN_CART', amountInCart)
        }
    },
    addItemToCart({commit, dispatch, state}, item) {
        commit('SET_EDITING_CART_STATUS', true)

        const dataForItemPost    = {
            cartItem: {
                sku                 : encodeURIComponent(item.sku),
                qty                 : parseInt(item.qty),
                quote_id            : state.cartId,
                extension_attributes: {}
            }
        }
        const requestForItemPost = {
            url   : this.$auth.loggedIn
                ? `carts/mine/items`
                : `${baseUrl}guest-carts/${state.cartId}/items`,
            method: 'POST'
        }

        magento
            .post(requestForItemPost.url, dataForItemPost, {
                headers: this.$auth.loggedIn
                    ? {Authorization: state.bearer}
                    : oauth.toHeader(oauth.authorize(requestForItemPost, token))
            })
            .then((res) => {
                const dataForItemPut    = {
                    cartItem: {
                        sku                 : encodeURIComponent(item.sku),
                        qty                 : parseInt(res.data.qty),
                        quote_id            : state.cartId,
                        extension_attributes: {}
                    }
                }
                const requestForItemPut = {
                    url   : this.$auth.loggedIn
                        ? `${baseUrl}carts/mine/items/${res.data.item_id}`
                        : `${baseUrl}guest-carts/${state.cartId}/items/${res.data.item_id}`,
                    method: 'PUT'
                }
                magento
                    .put(requestForItemPut.url, dataForItemPut, {
                        headers: this.$auth.loggedIn
                            ? {Authorization: state.bearer}
                            : oauth.toHeader(oauth.authorize(requestForItemPut, token))
                    })
                    .then(() => {
                        dispatch('fetchCart')
                        commit('SET_EDITING_CART_STATUS', false)
                        commit('SET_SUCCESS_CART_MESSAGE', 'Product toegevoegd!')
                        setTimeout(() => {
                            commit('SET_SUCCESS_CART_MESSAGE', '')
                        }, 2000)
                    })
                    .catch((error) => {
                        commit('SET_EDITING_CART_STATUS', false)
                        commit('SET_EDITING_CART_MESSAGE', error.response.data.message)
                    })
            })
            .catch((error) => {
                commit('SET_EDITING_CART_STATUS', false)
                commit('SET_EDITING_CART_MESSAGE', error.response.data.message)
            })
    },
    removeItemFromCart({commit, dispatch, state}, item) {
        commit('SET_EDITING_CART_STATUS', true)

        const requestForItemDelete = {
            url   : this.$auth.loggedIn
                ? `${baseUrl}carts/mine/items/${item.item_id}`
                : `${baseUrl}guest-carts/${state.cartId}/items/${item.item_id}`,
            method: 'DELETE'
        }
        magento
            .delete(requestForItemDelete.url, {
                headers: this.$auth.loggedIn
                    ? {Authorization: state.bearer}
                    : oauth.toHeader(oauth.authorize(requestForItemDelete, token))
            })
            .then(() => {
                dispatch('fetchCart')
            })
            .catch((error) => {
                commit('SET_EDITING_CART_STATUS', false)
                commit('SET_EDITING_CART_MESSAGE', error.response.data.message)
            })
    },
    editItemQuantityInCart({commit, dispatch, state}, item) {
        commit('SET_EDITING_CART_STATUS', true)

        const dataForItemEdit    = {
            cartItem: {
                sku                 : item.sku,
                qty                 : item.qty,
                quote_id            : state.cartId,
                extension_attributes: {}
            }
        }
        const requestForItemEdit = {
            url   : this.$auth.loggedIn
                ? `${baseUrl}carts/mine/items/${item.item_id}`
                : `${baseUrl}guest-carts/${state.cartId}/items/${item.item_id}`,
            method: 'PUT'
        }

        magento
            .put(requestForItemEdit.url, dataForItemEdit, {
                headers: this.$auth.loggedIn
                    ? {Authorization: state.bearer}
                    : oauth.toHeader(oauth.authorize(requestForItemEdit, token))
            })
            .then(() => {
                dispatch('fetchCart')
                commit('SET_EDITING_CART_STATUS', false)
            })
            .catch((error) => {
                commit('SET_EDITING_CART_STATUS', false)
                commit('SET_EDITING_CART_MESSAGE', error.response.data.message)
            })
    },
    async fetchCart({commit, dispatch, state}, fetchShipping = false) {
        const requestForCartGetTotals = {
            url   : this.$auth.loggedIn
                ? `${baseUrl}carts/mine/totals`
                : `${baseUrl}guest-carts/${state.cartId}/totals`,
            method: 'GET'
        }
        const requestForCartGet       = {
            url   : this.$auth.loggedIn
                ? `${baseUrl}carts/mine`
                : `${baseUrl}guest-carts/${state.cartId}`,
            method: 'GET'
        }

        const [cartTotals, cart] = await Promise.all([
            magento.get(requestForCartGetTotals.url, {
                headers: this.$auth.loggedIn
                    ? {Authorization: state.bearer}
                    : oauth.toHeader(oauth.authorize(requestForCartGetTotals, token))
            }),
            magento.get(requestForCartGet.url, {
                headers: this.$auth.loggedIn
                    ? {Authorization: state.bearer}
                    : oauth.toHeader(oauth.authorize(requestForCartGet, token))
            })
        ])

        cart.data.items.map((i) => {
            i.editQty = false
        })

        commit('SET_EDITING_CART_STATUS', false)
        commit('SET_CART', cartTotals.data)
        commit('SET_CART_ITEMS', cart.data.items)
        commit('SET_AMOUNT_IN_CART', cartTotals.data.items_qty)
        if (fetchShipping) {
            dispatch('fetchShippingCost')
        }
    },
    pushOrder({commit, dispatch, state}, info) {
        commit('SET_PUSH_ORDER_ERROR_MESSAGE', '')
        commit('SET_ORDERING_STATUS', true)

        const shippingAddress = {
            region     : '',
            region_id  : info.shipping.region_id,
            region_code: '',
            country_id : info.shipping.country_id,
            company    : info.shipping.company,
            street     : info.shipping.street,
            postcode   : info.shipping.postcode,
            city       : info.shipping.city,
            firstname  : info.shipping.firstname,
            lastname   : info.shipping.lastname,
            email      : info.shipping.email,
            telephone  : info.shipping.telephone
        }
        const billingAddress  = {
            region     : '',
            region_id  : info.billing.region_id,
            region_code: '',
            country_id : info.billing.country_id,
            company    : info.billing.company,
            street     : info.billing.street,
            postcode   : info.billing.postcode,
            city       : info.billing.city,
            firstname  : info.billing.firstname,
            lastname   : info.billing.lastname,
            email      : info.billing.email,
            telephone  : info.billing.telephone
        }

        const dataForShippingInformation    = {
            addressInformation: {
                shippingAddress,
                billingAddress,
                shipping_carrier_code: state.shippingInfo.carrier_code,
                shipping_method_code : state.shippingInfo.method_code
            }
        }
        const requestForShippingInformation = {
            url   : this.$auth.loggedIn
                ? `${baseUrl}carts/mine/shipping-information`
                : `${baseUrl}guest-carts/${state.cartId}/shipping-information`,
            method: 'POST'
        }
        magento
            .post(requestForShippingInformation.url, dataForShippingInformation, {
                headers: this.$auth.loggedIn
                    ? {Authorization: state.bearer}
                    : oauth.toHeader(
                        oauth.authorize(requestForShippingInformation, token)
                    )
            })
            .then((res) => {
                const dataForPaymentInformation    = {
                    paymentMethod: {
                        method: res.data.payment_methods[0].code
                    },
                    billingAddress,
                    email        : info.billing.email
                }
                const requestForPaymentInformation = {
                    url   : this.$auth.loggedIn
                        ? `${baseUrl}carts/mine/payment-information`
                        : `${baseUrl}guest-carts/${state.cartId}/payment-information`,
                    method: 'POST'
                }
                magento
                    .post(requestForPaymentInformation.url, dataForPaymentInformation, {
                        headers: this.$auth.loggedIn
                            ? {Authorization: state.bearer}
                            : oauth.toHeader(
                                oauth.authorize(requestForPaymentInformation, token)
                            )
                    })
                    .then((id) => {
                        this.$axios
                            .get(
                                `https://backend.mypall.com/payment/createPayment.php?orderid=${id.data}`
                            )
                            .then((resUrl) => {
                                // window.location.replace(resUrl.data)
                                this.$cookies.remove('cart_id')
                                commit('SET_PUSHED_ORDER_STATUS', resUrl.data)
                            })
                        // commit('SET_PUSHED_ORDER_STATUS', '/')
                    })
                    .catch((error) => {
                        commit('SET_ORDERING_STATUS', false)
                        commit('SET_PUSH_ORDER_ERROR_MESSAGE', error.response.data.message)
                    })
            })
            .catch((error) => {
                commit('SET_ORDERING_STATUS', false)
                commit('SET_PUSH_ORDER_ERROR_MESSAGE', error.response.data.message)
            })
    },
    fetchOrderHistory({commit, dispatch, state, context}) {
        const requestForOrderHistory = {
            url   : `${baseUrl}orders?searchCriteria[filterGroups][0][filters][0][field]=customer_id&searchCriteria[filterGroups][0][filters][0][value]=${context.state.auth.user.id}&searchCriteria[sortOrders][0][field]=entity_id&searchCriteria[sortOrders][0][direction]=DESC`,
            method: 'GET'
        }
        magento
            .get(requestForOrderHistory.url, {
                headers: oauth.toHeader(oauth.authorize(requestForOrderHistory, token))
            })
            .then((res) => {
                commit('SET_ORDER_HISTORY', res.data)
            })
    },
    async fetchShippingCost({commit, dispatch, state}, info) {
        commit('SET_LOADING_CART_STATUS', true)

        const dataForShippingCost    = {
            address: {
                region         : '',
                region_id      : info.shipping.region_id,
                region_code    : '',
                country_id     : info.shipping.country_id,
                company        : info.shipping.company,
                street         : info.shipping.street,
                postcode       : info.shipping.postcode,
                city           : info.shipping.city,
                firstname      : info.shipping.firstname,
                lastname       : info.shipping.lastname,
                email          : info.shipping.email,
                telephone      : info.shipping.telephone,
                same_as_billing: 1
            }
        }
        const requestForShippingCost = {
            url   : this.$auth.loggedIn
                ? `${baseUrl}carts/mine/estimate-shipping-methods`
                : `${baseUrl}guest-carts/${state.cartId}/estimate-shipping-methods`,
            method: 'POST'
        }

        await magento
            .post(requestForShippingCost.url, dataForShippingCost, {
                headers: this.$auth.loggedIn
                    ? {Authorization: state.bearer}
                    : oauth.toHeader(oauth.authorize(requestForShippingCost, token))
            })
            .then((res) => {
                res.data.map((method) => {
                    if (method.method_code === info.shippingMethod) {
                        commit('SET_SHIPPING_INFO', method)
                        commit('SET_LOADING_CART_STATUS', false)
                    }
                })
            })
    }
}
