import crypto from 'crypto'
import axios from 'axios'
import OAuth from 'oauth-1.0a'

export const oauth = OAuth({
  consumer: {
    key: '737ibpj15cx3mrj51nsop95ta97267dx',
    secret: 'tx3zernd0ba0d7q7xnoz7aul0kwwsgc2'
  },
  signature_method: 'HMAC-SHA1',
  // eslint-disable-next-line camelcase
  hash_function(base_string, key) {
    return crypto
      .createHmac('sha1', key)
      .update(base_string)
      .digest('base64')
  }
})

export const token = {
  key: 'mjepuekivt5he53te0d20c0xj68sk1ad',
  secret: 'ukdychhq9ewdjbplfo95mbkn3ml3hdxk'
}

export const baseUrl = process.env.baseUrlMagento

export const magento = axios.create({
  baseURL: process.env.baseUrlMagento,
  withCredentials: false,
  timeout: 30000
})
