import Vue from 'vue'
import Router from 'vue-router'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _6763be9f = () => interopDefault(import('../pages/account/index.vue' /* webpackChunkName: "pages/account/index" */))
const _3cc6badc = () => interopDefault(import('../pages/conditions/index.vue' /* webpackChunkName: "pages/conditions/index" */))
const _437cc36e = () => interopDefault(import('../pages/bedankt/index.vue' /* webpackChunkName: "pages/bedankt/index" */))
const _14ad606c = () => interopDefault(import('../pages/bestel-informatie/index.vue' /* webpackChunkName: "pages/bestel-informatie/index" */))
const _2b1c765c = () => interopDefault(import('../pages/contact/index.vue' /* webpackChunkName: "pages/contact/index" */))
const _2d62142c = () => interopDefault(import('../pages/klantenservice/index.vue' /* webpackChunkName: "pages/klantenservice/index" */))
const _33821c2e = () => interopDefault(import('../pages/nieuw-wachtwoord/index.vue' /* webpackChunkName: "pages/nieuw-wachtwoord/index" */))
const _f5b4f07c = () => interopDefault(import('../pages/about-us/index.vue' /* webpackChunkName: "pages/about-us/index" */))
const _da9cbb8c = () => interopDefault(import('../pages/privacy/index.vue' /* webpackChunkName: "pages/privacy/index" */))
const _41f3e45a = () => interopDefault(import('../pages/shop/index.vue' /* webpackChunkName: "pages/shop/index" */))
const _3a8c2631 = () => interopDefault(import('../pages/verkooppunt-worden/index.vue' /* webpackChunkName: "pages/verkooppunt-worden/index" */))
const _0929d804 = () => interopDefault(import('../pages/cart/index.vue' /* webpackChunkName: "pages/cart/index" */))
const _101f7566 = () => interopDefault(import('../pages/shop/3-products.vue' /* webpackChunkName: "pages/shop/3-products" */))
const _0f2c2a4a = () => interopDefault(import('../pages/shop/_product/index.vue' /* webpackChunkName: "pages/shop/_product/index" */))
const _740dff78 = () => interopDefault(import('../pages/shop/_product/2-products-related.vue' /* webpackChunkName: "pages/shop/_product/2-products-related" */))
const _af3005e0 = () => interopDefault(import('../pages/order/_/index.vue' /* webpackChunkName: "pages/order/_/index" */))
const _138329ea = () => interopDefault(import('../pages/mislukt/_/index.vue' /* webpackChunkName: "pages/mislukt/_/index" */))
const _74500b01 = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))

// TODO: remove in Nuxt 3
const emptyFn = () => {}
const originalPush = Router.prototype.push
Router.prototype.push = function push (location, onComplete = emptyFn, onAbort) {
  return originalPush.call(this, location, onComplete, onAbort)
}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: decodeURI('/'),
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/account",
    component: _6763be9f,
    name: "account___nl"
  }, {
    path: "/algemene-voorwaarden",
    component: _3cc6badc,
    name: "conditions___nl"
  }, {
    path: "/bedankt",
    component: _437cc36e,
    name: "bedankt___nl"
  }, {
    path: "/bestel-informatie",
    component: _14ad606c,
    name: "bestel-informatie___nl"
  }, {
    path: "/contact",
    component: _2b1c765c,
    name: "contact___nl"
  }, {
    path: "/klantenservice",
    component: _2d62142c,
    name: "klantenservice___nl"
  }, {
    path: "/nieuw-wachtwoord",
    component: _33821c2e,
    name: "nieuw-wachtwoord___nl"
  }, {
    path: "/over-ons",
    component: _f5b4f07c,
    name: "about-us___nl"
  }, {
    path: "/privacy",
    component: _da9cbb8c,
    name: "privacy___nl"
  }, {
    path: "/shop",
    component: _41f3e45a,
    name: "shop___nl"
  }, {
    path: "/verkooppunt-worden",
    component: _3a8c2631,
    name: "verkooppunt-worden___nl"
  }, {
    path: "/winkelwagen",
    component: _0929d804,
    name: "cart___nl"
  }, {
    path: "/shop/3-products",
    component: _101f7566,
    name: "shop-3-products___nl"
  }, {
    path: "/shop/:product",
    component: _0f2c2a4a,
    name: "shop-product___nl"
  }, {
    path: "/shop/:product/2-products-related",
    component: _740dff78,
    name: "shop-product-2-products-related___nl"
  }, {
    path: "/order/*",
    component: _af3005e0,
    name: "order-all___nl"
  }, {
    path: "/mislukt/*",
    component: _138329ea,
    name: "mislukt-all___nl"
  }, {
    path: "/",
    component: _74500b01,
    name: "index___nl"
  }],

  fallback: false
}

export function createRouter () {
  return new Router(routerOptions)
}
